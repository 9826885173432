import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App/App';
import { loadDatadogRum } from './modules/dataDogRum/dataDogRUM';
import type { ClientContext } from './types/types';

export default ({ environment }: ClientContext) => {
  if (environment !== 'development') {
    // TODO: find a way to dynamically pass in buildVersion
    loadDatadogRum({ environment, buildVersion: '1.0.0' });
  }

  hydrateRoot(
    document.getElementById('app')!,
    <BrowserRouter>
      <App environment={environment} />
    </BrowserRouter>,
  );
};
