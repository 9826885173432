import 'braid-design-system/reset';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type AxiosError, isAxiosError } from 'axios';
import {
  BraidProvider,
  Loader,
  Page,
  ToastProvider,
} from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { StrictMode, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from 'sku/@loadable/component';

import { Nav } from 'src/components/molecules/Nav/Nav';
import { ErrorMessage } from 'src/constants/errorMessages';
import { AppConfigContextProvider } from 'src/contexts/AppConfig/AppConfigProvider';
import { NavigationProvider } from 'src/contexts/Navigation/NavigationContext';
import { SmartHireClientProvider } from 'src/contexts/SmartHireClient/SmartHireClientProvider';
import { UserContextProvider } from 'src/contexts/UserContex/UserContextProvider';
import { NoAccess } from 'src/pages/NoAccess/NoAccess';
import type { ClientContext } from 'src/types/types';

interface AppProps {
  environment: ClientContext['environment'];
}

const Home = loadable(() => import('src/pages/Home/Home'), {
  resolveComponent: (m) => m.Home,
});

const LoginForm = loadable(() => import('src/pages/LoginForm/LoginForm'), {
  resolveComponent: (m) => m.LoginForm,
});

const Jobs = loadable(() => import('src/pages/Jobs/Jobs'), {
  resolveComponent: (m) => m.Jobs,
});

const AccountDetails = loadable(
  () => import('src/pages/AccountDetails/AccountDetails'),
  {
    resolveComponent: (m) => m.AccountDetails,
  },
);

const JobCandidateListing = loadable(
  () => import('src/pages/JobCandidateListing/JobCandidateListing'),
  {
    resolveComponent: (m) => m.JobCandidateListing,
  },
);

const ProtectedRoute = loadable(
  () => import('src/components/templates/ProtectedRoute/ProtectedRoute'),
  {
    resolveComponent: (m) => m.ProtectedRoute,
  },
);

export default ({ environment }: AppProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (_failureCount, error) => {
          if (error.message === ErrorMessage.REFRESH_TOKEN_FAILED) {
            return false;
          }

          if (isAxiosError(error)) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 404) {
              return false;
            }
          }

          return true;
        },
      },
    },
  });

  return (
    <StrictMode>
      <BraidProvider
        theme={{
          webFonts: seekJobs.webFonts,
          vanillaTheme: seekJobs.vanillaTheme,
          name: seekJobs.name,
          displayName: seekJobs.displayName,
          legacy: seekJobs.legacy,
          space: seekJobs.space,
          color: seekJobs.color,
          background: {
            lightMode: apac.background.lightMode,
            darkMode: apac.background.darkMode,
          },
          backgroundLightness: seekJobs.backgroundLightness,
        }}
      >
        <ToastProvider>
          <AppConfigContextProvider value={{ environment }}>
            <QueryClientProvider client={queryClient}>
              <SmartHireClientProvider>
                <UserContextProvider>
                  <NavigationProvider>
                    <Page footer={<></>}>
                      <Suspense fallback={<Loader />}>
                        <Nav />
                        <Routes>
                          <Route path="/login" element={<LoginForm />} />
                          <Route element={<ProtectedRoute />}>
                            <Route
                              path="/"
                              element={<Home environment={environment} />}
                            />
                            <Route path="/jobs" element={<Jobs />} />
                            <Route
                              path="/jobs/:jobId"
                              element={<JobCandidateListing />}
                            />
                            <Route
                              path="/account"
                              element={<AccountDetails />}
                            />
                            <Route
                              path="/account/hirers"
                              element={<AccountDetails />}
                            />
                          </Route>
                          <Route path="/forbidden" element={<NoAccess />} />
                        </Routes>
                      </Suspense>
                    </Page>
                  </NavigationProvider>
                </UserContextProvider>
              </SmartHireClientProvider>
            </QueryClientProvider>
          </AppConfigContextProvider>
        </ToastProvider>
      </BraidProvider>
    </StrictMode>
  );
};
